import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Grid, Skeleton, Card, CardActionArea, Button } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import { customerSelector } from "../../store/slices/customerSlice";
import { userSelector } from "../../store/slices/userSlice";
import {
  saveValue,
  userDetailsSelector,
  applicationSelector,
  getEmploymentVerification,
} from "../../store/slices/applicationFormSlice";
import {
  APPLICATION_TYPES,
  ERRORS_MESSAGE,
  FILE_AWAITING_MESSAGE,
  SUCCESS_MESSAGE,
  creditCardModalInfo,
} from "../../constants";
import { HtmlTooltip } from "../ProductSelector/Product";
// import RadioButtonGroup from "../Utils/RadioButtonGroup";

const EmploymentVerification = React.memo(({ customer, applicationId }) => {
  const dispatch = useDispatch();
  const applicationState = useSelector(applicationSelector);
  const userDetails = useSelector(userSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { isCustomerLogin } = useSelector(customerSelector);
  const employmentVerification = customer?.employmentVerification;
  const incomeInformation = employmentVerification?.instantResponse?.[0]?.incomeInformation?.[0];
  const { gettingEmploymentVerification } = useSelector(userDetailsSelector, isEqual);
  // const [customerSummaryMonths, setCustomerSummaryMonths] = useState("36");

  const labelStyle = {
    minHeight: "4.5em",
    minWidth: "8em",
    maxWidth: "7em",
    fontSize: "12px",
    color: "#777777",
  };
  const valueStyle = { fontSize: "12px" };
  // const disableButton = false

  // Need to disable it till customer not add any email
  const disableButton = !customer?.customerTitle
    || !customer?.firstName
    || !customer?.lastName
    || !customer?.phoneNumbers?.[0]?.number
    || !customer?.emails?.[0]?.address
    || (applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL && !applicationState?.asset?.assetValue)
    || !customer?.addresses?.[0]
    || gettingEmploymentVerification;

  const handleGetCreditFile = () => {
    dispatch(saveValue({ gettingEmploymentVerification: true }));
    dispatch(
      getEmploymentVerification({
        data: {
          customerId: customer._id,
          applicationId,
          summaryMonths: "36", // customerSummaryMonths,
        },
        isCustomerLogin,
      }),
    ).then((res) => {
      if (res?.payload?.data && Object.keys(res?.payload?.data).length > 0) {
        enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.employmentVerificationFetchMsg, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  // const handle = {
  //   setSummaryMonths: (day) => {
  //     setCustomerSummaryMonths(day);
  //   },
  // }

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
          Customer Summary
        </Typography>

        {employmentVerification !== undefined &&
          (!employmentVerification?._id || !employmentVerification?.instantResponse) && (
            <Typography
              variant="subtitle1"
              fontWeight={600}
              textAlign={"center"}
              sx={{ padding: "20px", marginBottom: "20px" }}
            >
              No Employment data available
            </Typography>
          )}

        {employmentVerification && employmentVerification?._id && employmentVerification?.instantResponse && (
          <Grid container spacing={2.5}>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Period Start Date</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payPeriodStartDate || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Period End Date</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payPeriodEndDate || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Period Hours Worked</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payPeriodHoursWorked || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Period Net Earnings</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payPeriodNetEarnings || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Last Pay Period Gross Earnings</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.lastPayPeriodGrossEarnings || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Period Base Pay</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payPeriodBasePay || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Year</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {employmentVerification?.instantResponse?.[0]?.grossIncomeSummary?.[0]?.year || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Base Pay</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {employmentVerification?.instantResponse?.[0]?.grossIncomeSummary?.[0]?.basePay || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Rate</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payRate || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4} lg={2.4} xl={1.2} container direction="column" alignItems="flex-start">
              <Typography style={labelStyle}>Pay Rate Description</Typography>
              <Typography style={valueStyle} fontWeight="bold">
                {incomeInformation?.payRateDescription || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        )}

        {((employmentVerification && !employmentVerification?.instantResponse) || !employmentVerification) && (

          <Grid container spacing={2} mb={"20px"}>

            <Grid item xs={12} sm={12}>
              {/* <Grid item sm={6} marginTop={"20px"} marginBottom={"20px"}>
                <Typography variant="subtitle2">Summary months</Typography>
                <RadioButtonGroup
                  required
                  name={"summaryMonths"}
                  value={customerSummaryMonths}
                  handleValue={(value) => {
                    handle.setSummaryMonths(value);
                  }}
                  optionStyle={{
                    width: "50%",
                    padding: "8px",
                    minHeight: "20px",
                  }}
                  options={[
                    { icon: "3", label: "3" },
                    { icon: "12", label: "12" },
                    { icon: "36", label: "36" },
                  ]}
                />
              </Grid> */}

            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Equifax Request Card */}
              <HtmlTooltip
                tooltipPadding={"20px"}
                title={
                  !userDetails?.is_service_enable && (
                    <React.Fragment>
                      <Typography color="inherit" marginBottom="5px">
                        {creditCardModalInfo.mainTitle}
                      </Typography>
                      <Typography color="inherit" fontSize="14px" marginBottom="10px">
                        {creditCardModalInfo.subTitle}
                      </Typography>
                      <Link to="/billing/creditCard">
                        <Button variant="outlined" size="small">
                          {creditCardModalInfo.btnText}
                        </Button>
                      </Link>
                    </React.Fragment>
                  )
                }
              >
                <Card>
                  <CardActionArea
                    disableRipple={disableButton}
                    onClick={() => {
                      if (!userDetails?.is_service_enable || disableButton) return;
                      handleGetCreditFile();
                    }}
                  >
                    <CustomCardSending
                      title={"Get Customer Employment Summary"}
                      disabled={!userDetails?.is_service_enable || disableButton}
                      description={` Get ${customer.firstName}'s employment report using Equifax Access Seeker.`}
                      imagePath={"/static/equifax-logo.png"}
                      loadingState={false}
                      financialState={false}
                      icon={false}
                      lastTitle={"Getting employment summary..."}
                    />
                  </CardActionArea>
                </Card>

                <Typography variant="caption">
                  {disableButton &&
                    `${!gettingEmploymentVerification ? "Requires" : ""}  
                    ${applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL && !applicationState?.asset?.assetValue ? "Purchase price, " : ""}
                    ${!customer?.firstName && !customer?.lastName ? `${applicationState?.applicationType === APPLICATION_TYPES.COMMERCIAL ? "guarantor" : "borrower"} full name, ` : ""}
                    ${!customer?.emails?.[0]?.address ? `email, ` : ""}
                    ${!customer?.phoneNumbers?.[0]?.number ? `mobile number, ` : ""}
                    ${!customer?.dateOfBirth ? `dob, ` : ""}
                    ${!customer?.customerTitle ? `title, ` : ""}
                    ${!customer?.addresses?.[0] ? `address ` : ""}
                `}
                </Typography>
              </HtmlTooltip>
            </Grid>
          </Grid>
        )}

        {gettingEmploymentVerification && (
          <>
            <Grid item container spacing={2} style={{ marginBottom: "10px", marginTop: "10px" }}>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={9}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={10}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {customer?.documents?.filter((doc) => doc.name === "Employment verification")
          ?.length <= 0 &&
          gettingEmploymentVerification && (
            <Stack spacing={1} direction="row" margin={"20px 0 0 0"}>
              <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingEquifaxCreditPDFMsg}</Typography>
            </Stack>
          )}

        <Stack
          spacing={1}
          direction="row"
          style={{ margin: "20px 0 10px 0" }}
        // divider={<Divider orientation="vertical" flexItem />}
        >
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Employment verification")
              .map((doc) => <Document document={doc} customer={customer} name="employmentVerification" />)}
        </Stack>
      </Grid>
    </>
  );
});

export default EmploymentVerification;
